import React from 'react';

export const Text = ({
  children,
  color,
  bold,
  margin,
  fontSize,
}: {
  children: any;
  color: string;
  bold: string;
  margin: string;
  fontSize: string;
}) => {
  return (
    <p
      style={{
        color: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)
          ? color
          : '#666666',
        fontWeight: bold ? 700 : 400,
        fontSize: fontSize ? fontSize : 'normal',
        margin: margin ? margin : '10px 0 10px 0',
        lineHeight: '1.5rem',
      }}
    >
      {children}
    </p>
  );
};
