import { NativeSelect, styled } from '@mui/material';
import { SelectType, TextSize } from '../../models/enum';

export const StyledSelectInput = styled(
  (props: any) => <NativeSelect {...props} />,
  {
    shouldForwardProp: (prop) =>
      prop !== 'textSize' && prop !== 'selectType' && prop !== 'textSize',
  }
)(({ theme, selectType, disabled, textSize }) => {
  selectType = disabled ? 'disabled' : selectType;

  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    background: theme.colors.disabled,
    borderColor: theme.colors.disabled,
  };

  const primaryStyle = {
    color: theme.colors.white,
    fill: theme.colors.white,
    background: theme.colors.primary,
    borderColor: theme.colors.borderInput,
  };

  const secondaryStyle = {
    color: 'unset',
    fill: theme.colors.primary,
    background: 'unset',
    borderColor: 'initial',
  };

  const smallStyle = {
    fontSize: '0.8rem',
  };

  const mediumStyle = {
    fontSize: '1rem',
  };

  const largeStyle = {
    fontSize: '1.2rem',
  };

  const typeStyle = () => {
    switch (selectType) {
      case SelectType.primary:
      default:
        return primaryStyle;
      case SelectType.secondary:
        return secondaryStyle;
      case SelectType.disabled:
        return disabledStyle;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
    }
  };

  return {
    '& select': {
      fontSize: sizeStyle().fontSize,
      borderRadius: 0,
      backgroundColor: typeStyle().background,
      borderColor: typeStyle().borderColor,
      paddingLeft: 0,
      padding: `4px ${theme.spacings.unit(2)} 4px ${theme.spacings.unit(4)}`,
      marginBottom: 0,
      color: typeStyle().color,

      '&:focus': {
        borderRadius: 0,
        backgroundColor: typeStyle().background,
      },
    },

    '& path': {
      fill: typeStyle().fill,
    },

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },

    '&&& option': {
      fontSize: sizeStyle().fontSize,
      color: theme.colors.primaryText,
    },
  };
});
