import { styled } from '@mui/material';

export const TableContainerStyled = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(9),
  table: {
    minWidth: theme.spacings.unit(626 / 4),
  },
  th: {
    fontSize: theme.fonts.utility.size(9),
  },
  td: {
    fontSize: theme.fonts.utility.size(9),
  },
}));
