import React, { useEffect, useState, useRef } from 'react';
import BaseCarousel from '../base-carousel/BaseCarousel';
import {
  Carousel,
  CarouselWrapper,
  Container,
  ImageWrapper,
} from './CarouselNewsImages.style';
import { Responsive } from 'react-alice-carousel';
import { useWindowSize } from 'usehooks-ts';
import PaginationArrowsMobile from '../../pagination-arrows-mobile/PaginationArrowsMobile';
import { getStrapiMedia } from '../../../utils/media';
import { Grid } from '@mui/material';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

interface CarouselNewsImageProps {
  images: any[];
  onImageClick?: (currentImage: number) => void;
  itemPerPage?: Responsive;
  useImageWrapper?: boolean;
  isPrerenderRequest: boolean;
}

const CarouselNewsImage: React.FC<CarouselNewsImageProps> = (props) => {
  const [columns] = useState<number>(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef();

  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);

  const { width } = useWindowSize();

  useEffect(() => {
    setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const repeaterRender = (items: any[]) => {
    return items.reduce((acc, curr, i) => {
      if (!acc || !acc[Math.floor(i / columns)]) {
        acc = {
          ...acc,
          [Math.floor(i / columns)]: [curr],
        };
      } else {
        acc[`${Math.floor(i / columns)}`].push(curr);
      }
      return acc;
    }, {} as any);
  };

  const imagesMapper = (images: any[]) =>
    images.flatMap(({ id, attributes }) => ({
      id,
      attributes,
    }));

  const carouselItems = React.Children.toArray(
    Object.values(
      (props.images &&
        Array.isArray(props.images) &&
        repeaterRender(imagesMapper(props.images))) ||
        []
    )?.map((image: any) => (
      <Grid container spacing={0}>
        {image?.map((data: any, i: number) => (
          <Grid item cols={{ xs: 12, sm: 12 }} key={i}>
            <>
              {props.useImageWrapper ? (
                <ImageWrapper height={data?.attributes?.height}>
                  <ImageWithFallback
                    src={getStrapiMedia(data?.attributes?.url)}
                    alt={data?.attributes?.alternativeText}
                    layout="fill"
                    objectFit="cover"
                    onClick={() => props?.onImageClick?.(data?.id)}
                    fallbackSrc={DEFAULT_IMAGE}
                    isPrerenderRequest={props.isPrerenderRequest}
                  />
                </ImageWrapper>
              ) : (
                <ImageWithFallback
                  src={getStrapiMedia(data?.attributes?.url)}
                  alt={data?.attributes?.alternativeText}
                  layout="intrinsic"
                  width={data?.attributes?.width}
                  height={data?.attributes?.height}
                  objectFit="contain"
                  onClick={() => props?.onImageClick?.(i)}
                  fallbackSrc={DEFAULT_IMAGE}
                  isPrerenderRequest={props.isPrerenderRequest}
                />
              )}
            </>
          </Grid>
        ))}
      </Grid>
    ))
  );

  return (
    <Carousel>
      <Container>
        <PaginationArrowsMobile
          index={activeIndex}
          totalItems={carouselItems.length}
          onLeftArrowClick={slidePrev}
          onRightArrowClick={slideNext}
        >
          <CarouselWrapper>
            <BaseCarousel
              {...props}
              responsive={props.itemPerPage}
              mouseTracking={true}
              disableButtonsControls
              disableDotsControls
              activeIndex={activeIndex}
              onSlideChanged={syncActiveIndex}
              items={carouselItems}
              dotsAlignment={'center'}
              refFromParentCb={(refFromChild: any) => {
                ref.current = refFromChild;
              }}
            />
          </CarouselWrapper>
        </PaginationArrowsMobile>
      </Container>
    </Carousel>
  );
};

export default CarouselNewsImage;
