import React from 'react';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { ColoredText } from './custom-tags/ColoredText';
import { Img } from './custom-tags/Img';
import { GridImages } from './custom-tags/GridImages';
import { Text } from './custom-tags/Text';
import { H1 } from './custom-tags/H1';
import { H2 } from './custom-tags/H2';
import { H3 } from './custom-tags/H3';
import { H4 } from './custom-tags/H4';
import { H5 } from './custom-tags/H5';
import { H6 } from './custom-tags/H6';
import { Blockquote } from './custom-tags/Blockquote';
import { Ol } from './custom-tags/Ol';
import { Ul } from './custom-tags/Ul';
import { HighlightedText } from './custom-tags/HighlightedText';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from './custom-tags/Table';
import { A } from './custom-tags/A';
import { Code } from './custom-tags/Code';

interface IProps {
  options?: MarkdownToJSX.Options;
  children?: any;
  id?: string;
  className?: string;
}

const CustomMarkdown: React.FC<IProps> = ({
  options,
  children,
  id,
  ...props
}: IProps) => {
  return (
    <Markdown
      {...props}
      id={id}
      options={{
        ...options,
        wrapper: 'div',
        forceWrapper: true,
        overrides: {
          // Default overrides
          img: Img,
          h1: H1,
          h2: H2,
          h3: H3,
          h4: H4,
          h5: H5,
          h6: H6,
          blockquote: Blockquote,
          ol: Ol,
          ul: Ul,
          table: Table,
          tbody: TableBody,
          thead: TableHead,
          tr: TableRow,
          td: TableCell,
          th: TableCell,
          a: A,
          code: Code,

          // Custom components
          ColoredText: ColoredText,
          HighlightedText: HighlightedText,
          Text: Text,
          GridImages: GridImages,
          Blockquote: Blockquote,

          // From users
          ...options?.overrides,
        },
      }}
      children={children}
    />
  );
};

export default CustomMarkdown;

export { MarkdownWrapper } from './Markdown.style';
