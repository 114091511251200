import { styled } from '@mui/material';

export const BlockquoteStyled = styled('blockquote')(({ theme }) => ({
  color: theme.colors.primaryText,
  marginLeft: 0,
  padding: `${theme.spacings.unit(24 / 4)} 0 ${theme.spacings.unit(
    24 / 4
  )} ${theme.spacings.unit(24 / 4)}`,
  borderLeft: `${theme.spacings.unit(2 / 4)} solid ${theme.colors.primary}`,
  fontSize: theme.fonts.utility.size(12),
  fontWeight: 500,
  lineHeight: theme.fonts.utility.size(36 / 2),
  p: {
    margin: 0,
    display: 'inline',
  },
}));

export const QuotationMarkStyled = styled('div')(({ theme }) => ({
  color: theme.colors.primary,
  marginBottom: `${theme.spacings.unit(20 / 4)}`,
  position: 'relative',
  minHeight: theme.spacings.unit(26 / 4),
  span: {
    fontSize: theme.fonts.utility.size(80 / 2),
    marginTop: theme.spacings.unit(20 / 4),
    marginLeft: `-${theme.spacings.unit(14 / 4)}`,
    position: 'absolute',
  },
}));

export const BlockquoteUserStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacings.unit(20 / 4)} 0 0 0`,
}));

export const BlockquoteImgWrapperStyled = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(56 / 4),
  width: theme.spacings.unit(56 / 4),
  display: 'flex',
  overflow: 'hidden',
  justifyContent: 'center',
  borderRadius: '50%',
  marginRight: theme.spacings.unit(12 / 4),
}));

export const BlockquoteImgStyled = styled('img')(() => ({}));

export const BlockquoteUserWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0,
}));

export const BlockquoteUserRoleStyled = styled('p')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(16 / 2),
  fontWeight: 400,
  lineHeight: theme.fonts.utility.size(24 / 2),
  '&&': {
    margin: 0,
  },
}));

export const BlockquoteUserNameStyled = styled('p')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(16 / 2),
  fontWeight: 500,
  lineHeight: theme.fonts.utility.size(24 / 2),
  '&&': {
    margin: 0,
  },
}));
