import qs from 'qs';
import {
  capitalizeFirstLetter,
  internationalizationToLanguages,
  multiCompanySafeEnv,
  strapiDateConverter,
} from 'corporate-utils';
import formatDate from 'date-fns/format';
import subDays from 'date-fns/subDays';
import subYears from 'date-fns/subYears';
import { AbortController } from 'node-abort-controller';

import {
  getCategoriesByParentCategoryId,
  getProductByIdAndCheckCategoryId,
} from '../services/dwh';
import {
  GlobalResponse,
  GlobalResponseStrapi,
  MegamenuLink,
} from '../models/domain/interfaces';
import { urlFromCategoriesIds } from './urls';
import { NextApiRequest, NextApiResponse } from 'next';
import {
  defaultInternationalization,
  defaultLanguage,
  Languages,
} from 'corporate-types';
import { getAvailableStringFromCategoryByLocale } from './categories';
import { Logger } from 'corporate-utils';

export function getStrapiURL(path: string) {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'
  }${path}`;
}

export const getDwhSecret = (): string => {
  const secret = {
    secret: process.env.API_DWH_AUTHENTICATION_SECRET,
    date: Date.now(),
  };

  return JSON.stringify(secret);
};

const getCorpSecret = (): string => {
  const secret = {
    secret: process.env.API_LAMBDA_CORP_SECRET,
    date: Date.now(),
  };

  return JSON.stringify(secret);
};

export const apiHandler = (
  fn: any,
  optionsParam?: {
    printRequest?: boolean;
    printResponse?: boolean;
    methodAllowed?: any;
    cache?: boolean;
  },
  from?: string
): any => {
  return async (req: NextApiRequest, res: NextApiResponse): Promise<any> => {
    if (from) {
      Logger.info('packages/corporate-ui/src/utils/api.ts called by', from);
    }
    const optionsLocal = {
      printRequest: false,
      printResponse: false,
      methodAllowed: undefined,
      cache: true,
      ...optionsParam,
    };
    const { printRequest, printResponse, methodAllowed, cache } =
      optionsLocal || {};
    let methodAllowedLocal = ['GET', 'HEAD', 'OPTIONS'];

    if (printRequest) {
      Logger.info('packages/corporate-ui/src/utils/api.ts req', req);
    }

    if (printResponse) {
      Logger.info('packages/corporate-ui/src/utils/api.ts req', res);
    }

    if (methodAllowed) {
      methodAllowedLocal = [
        ...new Set([...methodAllowedLocal, ...methodAllowed]),
      ];
    }
    const conditionToBeRejected =
      req?.headers?.['sec-fetch-site'] !== 'same-origin' &&
      req?.headers?.['sec-fetch-site'] !== undefined &&
      req?.headers?.['user-agent']?.indexOf?.('node-fetch') === -1 &&
      methodAllowedLocal.indexOf(req?.headers?.method as string) === -1;

    if (conditionToBeRejected && process.env.NEXT_PUBLIC_ENV !== 'LOCAL') {
      return res.status(401).json({
        error: 'Access denied',
      });
    } else {
      try {
        if (cache) {
          res.setHeader(
            'Cache-Control',
            'public, max-age=86400, must-revalidate'
          );
        } else {
          res.setHeader(
            'Cache-Control',
            'no-cache, max-age=0, must-revalidate'
          );
        }
        const result = await fn(req, res);
        return res.status(200).json(result);
      } catch (error) {
        Logger.error('packages/corporate-ui/src/utils/api.ts error', error);
        res.setHeader('Cache-Control', 'no-cache, max-age=0, must-revalidate');
        return res.status(500).send({
          error: JSON.stringify(error),
        });
      }
    }
  };
};

export const fetchCorpAPI = async (path: string, options = {}) => {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const response = await fetch('http://localhost:3333/api/crypto-token', {
    method: 'POST',
    body: JSON.stringify({
      secret: getCorpSecret(),
      pKeyType: 'corp',
    }),
    signal,
  });
  setTimeout(() => controller.abort(), 5000);
  const responseParsed = await response.json();
  const { token: authToken }: any = responseParsed;

  const controller2 = new AbortController();
  const signal2: any = controller2.signal;

  // Merge default and user options
  const mergedOptions = {
    headers: {
      authorization: authToken,
    },
    signal: signal2,
    ...options,
  };

  try {
    const result = await fetch(path, mergedOptions);
    setTimeout(() => controller.abort(), 5000);
    return result;
  } catch (error) {
    Logger.error(JSON.stringify(error));
    return null;
  }
};

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {RequestInit} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchStrapiAPI(
  path: string,
  urlParamsObject = {},
  options = {}
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      mode: 'same-origin',
    },
    signal,
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`
  )}`;

  // Trigger API call

  try {
    const response = await fetch(requestUrl, mergedOptions);
    setTimeout(() => controller.abort(), 5000);
    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error(JSON.stringify(error));
    throw new Error('An error occured please try again');
  }
}

export async function fetchHubspotAPI(path: string, options = {}) {
  // Trigger API call
  try {
    const controller = new AbortController();
    const signal: any = controller.signal;
    const response = await fetch(path, { signal, ...options });
    setTimeout(() => controller.abort(), 5000);
    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error(JSON.stringify(error));
    throw error;
  }
}

/**
 * Helper to make POST requests to DWH API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {RequestInit} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchDWHAPI(path: string, options = {}) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const response = await fetch('http://localhost:3333/api/crypto-token', {
    method: 'POST',
    body: JSON.stringify({
      secret: getDwhSecret(),
      pKeyType: 'dwh',
    }),
    signal,
  });
  setTimeout(() => controller.abort(), 5000);
  const responseParsed = await response.json();
  const { token: authToken }: any = responseParsed;

  const controller2 = new AbortController();
  const signal2: any = controller2.signal;
  // Merge default and user options
  const mergedOptions = {
    headers: {
      authorization: authToken,
    },
    signal: signal2,
    ...options,
  };

  // Trigger API call

  try {
    const response = await fetch(path, mergedOptions);
    setTimeout(() => controller2.abort(), 5000);
    const data = await response.json();
    return data;
  } catch (error) {
    Logger.error(JSON.stringify(error));
    return null;
  }
}

export async function getCategory({
  filters,
  locale,
  publicationState,
  populate,
}: {
  filters: any;
  locale: string;
  publicationState: boolean | null;
  populate?: string[] | null;
}) {
  const category = await fetchStrapiAPI('/categories', {
    filters,
    populate: populate || [
      'banner',
      'banner.image',
      'banner.mobileImage',
      'metadata',
      'metadata.shareImage',
      'categoryTexts',
      'subcategorySeo',
      'subcategorySeo.categoryTexts',
      'contactForm',
    ],
    publicationState: publicationState ? 'preview' : 'live',
    locale,
    pagination: {
      pageSize: 1000,
    },
  });

  if (category.data == null || category.data.length === 0) {
    return null;
  }

  // Return the first item since there should only be one result per slug
  return category?.data.length === 1 ? category?.data[0] : category?.data;
}

// NOT USED
export async function getCategoryUI({
  filters,
  locale,
  publicationState,
}: {
  filters: any;
  locale: string;
  publicationState: boolean | null;
}) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const controller = new AbortController();
  const signal: any = controller.signal;
  const opts = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      filters,
      locale,
      publicationState,
    }),
    params: JSON.stringify({
      filters,
      locale,
      publicationState,
    }),
    signal,
  };
  const categoriesGetFromCms = await fetch('/api/categories-cms', opts);
  setTimeout(() => controller.abort(), 5000);

  const categories = await categoriesGetFromCms.json();
  return categories;
}

export async function getNews({
  locale,
  filters,
  sort,
  pageSize = 24,
  page = 1,
  datesFormat,
}: {
  locale: string | undefined;
  page?: any;
  pageSize?: any;
  filters?: any;
  sort?: string[];
  datesFormat?: string;
}) {
  const news = await fetchStrapiAPI(
    '/news',
    {
      filters: {
        publishedAt: {
          $notNull: true,
        },
        ...filters,
      },
      sort,
      pagination: {
        pageSize: isNaN(parseInt(pageSize, 10)) ? 24 : pageSize,
        page: isNaN(parseInt(page, 10)) ? 1 : page,
      },
      populate: [
        'button',
        'thumbnail',
        'coverImage',
        'cornerImage',
        'mobileThumbnail',
        'mobileCoverImage',
        'video',
        'mobileVideo',
        'button',
        'metadata',
        'metadata.shareImage',
        'types',
      ],
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (news.data == null || news.data.length === 0) {
    return null;
  }

  return strapiDateConverter(news, 'news', datesFormat, locale);
}

export async function getNewsUi(values: {
  locale: string | undefined;
  page?: any;
  pageSize?: any;
  filters?: any;
  sort?: string[];
  datesFormat?: string;
}) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const controller = new AbortController();
  const signal: any = controller.signal;
  const opts = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(values),
    params: JSON.stringify(values),
    signal,
  };
  const getNews = await fetch('/api/news', opts);
  setTimeout(() => controller.abort(), 5000);

  const news = await getNews?.json?.();
  return news;
}

export async function getWorkshops({
  locale,
  filters,
  sort,
  pageSize = 24,
  page = 1,
  datesFormat,
}: {
  locale: string | undefined;
  page?: any;
  pageSize?: any;
  filters?: any;
  sort?: string[];
  datesFormat?: string;
}) {
  const workshops = await fetchStrapiAPI(
    '/workshops',
    {
      filters: {
        publishedAt: {
          $notNull: true,
        },
        ...filters,
      },
      sort,
      pagination: {
        pageSize: isNaN(parseInt(pageSize, 10)) ? 24 : pageSize,
        page: isNaN(parseInt(page, 10)) ? 1 : page,
      },
      populate: [
        'button',
        'localizations',
        'thumbnail',
        'coverImage',
        'cornerImage',
        'mobileThumbnail',
        'video',
        'mobileVideo',
        'mobileCoverImage',
        'metadata',
        'metadata.shareImage',
        'personalization',
        'personalization.styles',
      ],
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (workshops.data == null || workshops.data.length === 0) {
    return null;
  }

  return strapiDateConverter(workshops, 'workshops', datesFormat, locale);
}

export async function getWorkshop({
  slug,
  locale,
  dateFormat,
}: {
  slug: string;
  locale: string | undefined;
  dateFormat: string;
}) {
  const workshop = await fetchStrapiAPI(
    '/workshops',
    {
      filters: {
        slug: {
          $eq: slug,
        },
        publishedAt: {
          $notNull: true,
        },
      },
      populate: {
        contentSections: {
          populate: [
            'background',
            'card.button',
            'card.image',
            'card.mobileImage',
            'cardBio.button',
            'cardBio.image',
            'cardBio.rightBackground',
            'cardBio.rightImage',
            'cards.button',
            'cards.image',
            'cards.mobileImage',
            'event',
            'events',
            'events.bottomImages',
            'events.coverImage',
            'events.mobileCoverImage',
            'factory',
            'factory.image',
            'image',
            'images',
            'location',
            'location.image',
            'logo',
            'mainImage',
            'markdown',
            'media',
            'media.media',
            'news',
            'news.bottomImages',
            'news.coverImage',
            'news.mobileCoverImage',
            'presentationConfigs',
            'products',
            'size',
            'sliderConfigs',
            'topIcon',
            'types',
            'workshop',
            'workshop.coverImage',
            'workshop.mobileCoverImage',
            'workshop.mobileThumbnail',
            'workshop.thumbnail',
            'personalization',
            'personalization.styles',
          ],
        },
        metadata: {
          populate: ['*', 'shareImage'],
        },
        localizations: '*',
        thumbnail: '*',
        coverImage: '*',
        mobileThumbnail: '*',
        mobileCoverImage: '*',
        video: '*',
        mobileVideo: '*',
        bottomImages: '*',
        types: '*',
        personalizationForm: {
          populate: ['*', 'styles'],
        },
        workshopForm: {
          populate: {
            form: {
              populate: ['*', 'form', 'professionItems', 'coursesItems'],
            },
          },
        },
      },
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (workshop.data == null || workshop.data.length === 0) {
    return null;
  }

  return strapiDateConverter(workshop, 'workshops', dateFormat, locale).data[0];
}

export async function getSingleNews({
  slug,
  locale,
  dateFormat,
}: {
  slug: string;
  locale: string | undefined;
  dateFormat: string;
}) {
  const news = await fetchStrapiAPI(
    '/news',
    {
      filters: {
        slug: {
          $eq: slug,
        },
        publishedAt: {
          $notNull: true,
        },
      },
      populate: {
        contentSections: {
          populate: [
            'background',
            'card.button',
            'card.image',
            'card.mobileImage',
            'cardBio.button',
            'cardBio.image',
            'cardBio.rightBackground',
            'cardBio.rightImage',
            'cards.button',
            'cards.image',
            'cards.mobileImage',
            'event',
            'events',
            'events.bottomImages',
            'events.coverImage',
            'events.mobileCoverImage',
            'factory',
            'factory.image',
            'image',
            'images',
            'location',
            'location.image',
            'logo',
            'mainImage',
            'markdown',
            'media',
            'media.media',
            'news',
            'news.bottomImages',
            'news.coverImage',
            'news.mobileCoverImage',
            'presentationConfigs',
            'products',
            'size',
            'sliderConfigs',
            'topIcon',
            'types',
            'workshop',
            'workshop.coverImage',
            'workshop.mobileCoverImage',
            'workshop.mobileThumbnail',
            'workshop.thumbnail',
            'personalization',
            'personalization.styles',
          ],
        },
        metadata: {
          populate: ['*', 'shareImage'],
        },
        localizations: '*',
        thumbnail: '*',
        coverImage: '*',
        mobileThumbnail: '*',
        mobileCoverImage: '*',
        video: '*',
        mobileVideo: '*',
        bottomImages: '*',
        types: '*',
        productList: '*',
        categoryList: '*',
      },
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (news.data == null || news.data.length === 0) {
    return null;
  }

  return strapiDateConverter(news, 'news', dateFormat, locale).data[0];
}

export async function getEvents({
  locale,
  pageSize = 24,
  sort,
  datesFormat,
  filters,
}: {
  locale: string | undefined;
  pageSize?: number;
  sort?: string[];
  datesFormat?: string;
  filters?: any;
}) {
  let events = await fetchStrapiAPI(
    '/events',
    {
      filters: {
        publishedAt: {
          $notNull: true,
        },
        endDate: {
          $gte: formatDate(new Date(), 'y-MM-dd'),
        },
        ...filters,
      },
      sort,
      pagination: {
        pageSize,
      },
      populate: [
        'button',
        'thumbnailForEventList',
        'thumbnail',
        'coverImage',
        'mobileThumbnail',
        'mobileCoverImage',
        'video',
        'mobileVideo',
        'button',
        'metadata',
        'cornerImage',
        'metadata.shareImage',
        'presentationConfigs',
        'logoNation',
        'logoPartecipation',
        'topIcon',
        'types',
      ],
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (events.data == null || events.data.length === 0) {
    return null;
  }

  events = strapiDateConverter(events, 'events', datesFormat, locale).data;

  return events;
}

export async function getPastEvents({
  locale,
  datesFormat,
}: {
  locale: string | undefined;
  datesFormat?: string;
}) {
  const events = await fetchStrapiAPI(
    '/events',
    {
      filters: {
        publishedAt: {
          $notNull: true,
        },
        endDate: {
          $gte: formatDate(subYears(new Date(), 1), 'y-MM-dd'),
          $lte: formatDate(subDays(new Date(), 1), 'y-MM-dd'),
        },
      },
      pagination: {
        pageSize: 24,
      },
      populate: [
        'metadata',
        'metadata.shareImage',
        'logoNation',
        'logoPartecipation',
        'types',
      ],
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (events.data == null || events.data.length === 0) {
    return null;
  }

  return strapiDateConverter(events, 'events', datesFormat, locale).data;
}

export async function getEvent({
  slug,
  locale,
  dateFormat,
}: {
  slug: string;
  locale: string | undefined;
  dateFormat: string;
}) {
  const event = await fetchStrapiAPI(
    '/events',
    {
      filters: {
        slug: {
          $eq: slug,
        },
        publishedAt: {
          $notNull: true,
        },
      },
      populate: {
        contentSections: {
          populate: [
            'background',
            'card.button',
            'card.image',
            'card.mobileImage',
            'cardBio.button',
            'cardBio.image',
            'cardBio.rightBackground',
            'cardBio.rightImage',
            'cards.button',
            'cards.image',
            'cards.mobileImage',
            'event',
            'events',
            'events.bottomImages',
            'events.coverImage',
            'events.mobileCoverImage',
            'factory',
            'factory.image',
            'image',
            'images',
            'location',
            'location.image',
            'logo',
            'mainImage',
            'markdown',
            'media',
            'media.media',
            'news',
            'news.bottomImages',
            'news.coverImage',
            'news.mobileCoverImage',
            'presentationConfigs',
            'products',
            'size',
            'sliderConfigs',
            'topIcon',
            'types',
            'workshop',
            'workshop.coverImage',
            'workshop.mobileCoverImage',
            'workshop.mobileThumbnail',
            'workshop.thumbnail',
            'personalization',
            'personalization.styles',
          ],
        },
        metadata: {
          populate: ['*', 'shareImage'],
        },
        localizations: '*',
        thumbnail: '*',
        coverImage: '*',
        mobileThumbnail: '*',
        mobileCoverImage: '*',
        bottomImages: '*',
        video: '*',
        mobileVideo: '*',
        types: '*',
        locationSchema: '*',
        logoNation: '*',
        logoPartecipation: '*',
      },
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (event.data == null || event.data.length === 0) {
    return null;
  }

  strapiDateConverter(event, 'events', dateFormat, locale);

  return event.data[0];
}

export async function getPageData({
  filters,
  locale,
  publicationState,
}: {
  filters: any;
  locale: string;
  publicationState: boolean | null | undefined;
}) {
  const pagesData = await fetchStrapiAPI('/pages', {
    filters,
    populate: {
      contentSections: {
        populate: [
          'background',
          'boxes.button',
          'boxes.image',
          'button',
          'card.button',
          'card.image',
          'card.mobileImage',
          'cardBio.button',
          'cardBio.image',
          'cardBio.rightBackground',
          'cardBio.rightImage',
          'cardPersonalization.styles',
          'cards',
          'cards.button',
          'cards.image',
          'cards.mobileImage',
          'columnsNumber',
          'cornerImage',
          'event',
          'events',
          'events.bottomImages',
          'events.coverImage',
          'events.mobileCoverImage',
          'events.types',
          'factory',
          'factory.image',
          'icon',
          'image',
          'images',
          'location',
          'location.image',
          'logo',
          'mainImage',
          'mainTitlePersonalization',
          'titlePersonalization',
          'descriptionPersonalization',
          'markdown',
          'media',
          'media.media',
          'news',
          'news.bottomImages',
          'news.coverImage',
          'news.mobileCoverImage',
          'news.video',
          'news.mobileVideo',
          'personalization',
          'personalizationCard',
          'personalizationHeader',
          'personalization.styles',
          'presentationConfigs',
          'products',
          'sectionHeader',
          'sectionHeader.personalization',
          'sectionHeader.personalization.styles',
          'sectionHeader.topIcon',
          'personalizationCard.styles',
          'personalizationHeader.styles',
          'size',
          'sliderConfigs',
          'topIcon',
          'types',
          'workshop',
          'workshop.coverImage',
          'workshop.mobileCoverImage',
          'workshop.mobileThumbnail',
          'workshop.thumbnail',
          'workshop.video',
          'workshop.mobileVideo',
          'workshopCard',
          'workshopCard.image',
          'video',
          'mobileVideo',
          'professionItems',
        ],
      },
      localizations: '*',
      metadata: {
        populate: ['*', 'shareImage'],
      },
    },
    publicationState: publicationState ? 'preview' : 'live',
    locale: locale,
  });

  if (pagesData.data == null || pagesData.data.length === 0) {
    return null;
  }

  // Return the first item since there should only be one result per slug
  return pagesData.data[0];
}

export async function getPageTitleData({
  filters,
  locale,
  publicationState,
}: {
  filters: any;
  locale: string;
  publicationState: boolean | null | undefined;
}) {
  const pagesData = await fetchStrapiAPI('/pages', {
    filters,
    populate: {
      contentSections: {
        populate: ['shortName'],
      },
      localizations: '*',
    },
    publicationState: publicationState ? 'preview' : 'live',
    locale: locale,
  });

  if (pagesData.data == null || pagesData.data.length === 0) {
    return null;
  }

  // Return the first item since there should only be one result per slug
  return pagesData.data[0];
}

export async function getGlobalMetaData(
  locale: string
): Promise<GlobalResponse> {
  const globalRes: GlobalResponseStrapi = await fetchStrapiAPI(
    '/global',
    {
      populate: {
        metadata: {
          populate: ['*', 'shareImage'],
        },
        metaPWA: {
          populate: [
            'iconIphone',
            'iconIphone57x57',
            'iconIphone60x60',
            'iconIphone72x72',
            'iconIphone76x76',
            'iconIphone114x114',
            'iconIphone120x120',
            'iconIphone144x144',
            'iconIphone152x152',
            'iconIphone167x167',
            'iconIphone180x180',
            'icon16x16',
            'icon32x32',
            'icon64x64',
            'icon96x96',
            'icon128x128',
            'icon196x196',
            'androidChrome192x192',
          ],
        },
        favicon: {
          populate: '*',
        },
      },
      locale: locale,
    },
    {
      encodeValuesOnly: true,
    }
  );

  return {
    attributes: {
      ...globalRes?.data?.attributes,
    },
    meta: {
      ...globalRes?.meta,
    },
  };
}

export async function getGlobalData(
  locale: string,
  resolvedUrl?: string
): Promise<GlobalResponse> {
  const DEBUG = multiCompanySafeEnv({
    key: 'CUSTOM_LAMBDA_LOGGER_LEVEL',
    fallbackValue: 'WARN',
  });
  const startTs = Date.now();
  let delta = Date.now();
  let nowTs;

  const showMessage: any[] = [
    `${resolvedUrl} getGlobalData Start: ${new Date(startTs)} ${startTs}`,
  ];

  const globalRes: GlobalResponseStrapi = await fetchStrapiAPI(
    '/global',
    {
      populate: {
        metadata: {
          populate: ['shareImage'],
        },
        metaPWA: {
          populate: [
            'iconIphone',
            'iconIphone57x57',
            'iconIphone60x60',
            'iconIphone72x72',
            'iconIphone76x76',
            'iconIphone114x114',
            'iconIphone120x120',
            'iconIphone144x144',
            'iconIphone152x152',
            'iconIphone167x167',
            'iconIphone180x180',
            'icon16x16',
            'icon32x32',
            'icon64x64',
            'icon96x96',
            'icon128x128',
            'icon196x196',
            'androidChrome192x192',
          ],
        },
        favicon: {
          populate: ['*'],
        },
        footer: {
          populate: [
            '*',
            'logo',
            'imageInternalLinkPSC',
            'imageInternalLinkPSC.image',
            'imageInternalLinkPSC.page',
          ],
        },
        header: {
          populate: [
            'logo',
            'mainLinks',
            'mainLinks.subLink',
            'megamenuLinks.coverImage',
            'megamenuLinks.iconAsset',
            'reservedArea',
            'reservedArea.subLink',
          ],
        },
        fixedPageUrls: {
          populate: ['*'],
        },
        localizations: {
          populate: ['fixedPageUrls'],
        },
      },
      locale: locale,
    },
    {
      encodeValuesOnly: true,
    }
  );

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData After download globalRes ${new Date(nowTs)} ${
      nowTs - delta
    }ms`
  );
  delta = Date.now();

  // Get header link - father category
  const { megamenuLinks } = globalRes?.data?.attributes?.header || {};

  // Download the child categories of father category from DWH
  const categories = await Promise.all(
    (megamenuLinks || [])?.map?.(async ({ idDwh }) => {
      return (
        idDwh && (await getCategoriesByParentCategoryId(idDwh, false, locale))
      );
    })
  );

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData After download categories from dwh ${new Date(
      nowTs
    )} ${nowTs - delta}ms`
  );

  delta = Date.now();

  // Download the child categories of father category from STRAPI
  // 1. far un hash per le posizioni corrette
  // 2. collezionare tutti gli id che che servono e fare 1 chiamata
  // 3. ricreare categoriesCms via script

  const idsDwh: string[] = [];
  const categoriesCmsHashPosition = categories
    ?.filter?.((category: any) => !!category)
    ?.map?.((category: any) => {
      const childs = category?.filter?.(
        (category: any) => category?.depth !== 0
      );
      if (childs?.length > 0) {
        return childs?.map?.(({ id }: any) => {
          idsDwh.push(id?.toString());
          return id;
        });
      }
    });

  const categoryFromCmsRaw = await getCategory({
    filters: {
      idDwh: {
        $in: idsDwh,
      },
    },
    locale: locale,
    publicationState: true,
  });

  const categoriesCms = categoriesCmsHashPosition?.map?.(
    (categoriesCmsHashPositionRecord: string[]) => {
      const c = categoriesCmsHashPositionRecord?.map?.((categoryId: string) => {
        return categoryFromCmsRaw?.find?.((cfcr: any) => {
          const { attributes } = cfcr || {};
          const { idDwh } = attributes || {};
          return idDwh && idDwh.toString() === categoryId.toString();
        });
      });

      const b = c?.filter?.((found) => !!found);
      return b;
    }
  );

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData After download categories from strapi ${new Date(
      nowTs
    )} ${nowTs - delta}ms`
  );
  delta = Date.now();

  const categoriesCmsFlat =
    Array.isArray(categoriesCms) &&
    categoriesCms
      ?.filter((noUndefined: any) => !!noUndefined)
      ?.reduce?.((acc: any, currentValue: any) => {
        try {
          return [...(acc as any), ...(currentValue as any)];
        } catch (error) {
          console.error(error);
          return [...(acc as any)];
        }
      }, []);

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData After categoriesCmsFlat ${new Date(nowTs)} ${
      nowTs - delta
    }ms`
  );
  delta = Date.now();

  // Download the raccomended products of the father category
  const products = await Promise.all(
    (megamenuLinks || [])?.map?.(async (category: any) => {
      const { recommendedProductIdDwh, idDwh } = category;

      // TODO: what is any?
      const product = await getProductByIdAndCheckCategoryId(
        recommendedProductIdDwh,
        idDwh,
        locale
      );

      const convertedLocale: Languages =
        (Object.values(Languages) as string[]).indexOf(locale) !== -1
          ? (locale as Languages)
          : internationalizationToLanguages(
            locale || defaultInternationalization,
            'packages/corporate-ui/src/utils/api.ts - getGlobalData - download products categoriesCalculatedInfo'
          ) || defaultLanguage;

      product.categoriesCalculatedInfo =
        product?.categoriesCalculatedInfo?.map?.(
          (categoriesCalculatedInfo: any) => {
            return (
              categoriesCalculatedInfo?.map?.((categoryCalculatedInfo: any) => {
                const name = getAvailableStringFromCategoryByLocale(
                  categoryCalculatedInfo,
                  locale
                );

                return {
                  id: categoryCalculatedInfo?.id,
                  idFather: categoryCalculatedInfo?.idFather,
                  depth: categoryCalculatedInfo?.depth,
                  [`name${capitalizeFirstLetter(convertedLocale)}`]: name,
                };
              }) || []
            );
          }
        ) || [];

      return product;
    })
  );

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData Download the raccomended products of the father category ${new Date(
      nowTs
    )} ${nowTs - delta}ms`
  );

  delta = Date.now();

  // For all the father category we are going to add
  // - all the child categories
  //    - for every childs with depth 1 from father category get also the information from STRAPI
  // - the raccomended product

  // TODO: what is any?
  const megamenuLinksUpdatedWithFirstChilds =
    categoriesCmsFlat &&
    (megamenuLinks || [])
      ?.filter((noUndefined: any) => !!noUndefined)
      ?.map?.((link: MegamenuLink) => {
        const { idDwh, recommendedProductIdDwh } = link || {};

        const firstChild = categories
          ?.filter?.((noUndefined: any) => !!noUndefined)
          ?.filter?.((noUndefined: any) => noUndefined?.length > 0)
          ?.find?.((value: any) => {
            const [{ idFather }] = value;
            return idDwh !== undefined && ~~idDwh === ~~idFather;
          })
          ?.map((category: any) => {
            const cmsInfo = categoriesCmsFlat?.find?.((cat: any) => {
              return ~~cat?.attributes?.idDwh === ~~category?.id;
            });

            return {
              ...category,
              cmsInfo,
            };
          });

        const recommendedProduct = products
          ?.filter((noUndefined: any) => !!noUndefined)
          ?.find?.(({ sku }: { sku: string }) => {
            try {
              if (sku && recommendedProductIdDwh) {
                const isSkuEncoded = decodeURIComponent(sku) !== sku;
                const isRecommendedProductIdDwhEncoded =
                  decodeURIComponent(recommendedProductIdDwh as string) !==
                  recommendedProductIdDwh;
                const match =
                  (isSkuEncoded ? sku : encodeURIComponent(sku)) ===
                  (isRecommendedProductIdDwhEncoded
                    ? recommendedProductIdDwh
                    : encodeURIComponent(recommendedProductIdDwh as string));
                return match;
              } else {
                return false;
              }
            } catch (error) {
              Logger.error(
                'packages/corporate-ui/src/utils/api.ts error recommendedProduct',
                error
              );
              console?.trace?.();
            }
          });
        return {
          ...(link || {}),
          firstChild,
          recommendedProduct,
        };
      });

  megamenuLinksUpdatedWithFirstChilds &&
    megamenuLinksUpdatedWithFirstChilds
      ?.filter((noUndefined: any) => !!noUndefined)
      ?.forEach?.((e: any) => {
        if (e && e.recommendedProduct) {
          e.recommendedProduct.link =
            '/' +
            locale +
            (urlFromCategoriesIds(
              megamenuLinksUpdatedWithFirstChilds,
              e?.recommendedProduct?.categoriesCalculated,
              locale,
              'product',
              {
                sku: e?.recommendedProduct?.sku,
                nameFather: e?.recommendedProduct?.nameFather,
              },
              'packages/corporate-ui/src/utils/api.ts'
            ) || '/');
          e.recommendedProduct.otherProductsLink =
            e?.recommendedProduct?.link != `/${locale}/`
              ? e?.recommendedProduct?.link?.split('/').slice(0, 4).join('/')
              : `/${locale}/`;
        }
      });

  // Update the megamenuLinks with all the missing information
  const globalData: GlobalResponse = {
    attributes: {
      ...globalRes?.data?.attributes,
      header: {
        ...globalRes?.data?.attributes?.header,
        megamenuLinks: megamenuLinksUpdatedWithFirstChilds as any,
      },
    },
    meta: {
      ...globalRes?.meta,
    },
  };

  nowTs = Date.now();
  showMessage.push(
    `${resolvedUrl} getGlobalData End ${new Date(nowTs)} - ${nowTs - startTs}ms`
  );

  if (DEBUG || nowTs - startTs > 150) {
    Logger.error(showMessage);
  }

  return globalData;
}

export async function getSearchResults(
  search: string,
  captchaToken: {
    token: string;
    alternativeCaptcha: boolean;
  },
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const { token, alternativeCaptcha } = captchaToken || {};
  const response = await fetch(
    `/api/products/search-server?input=${search}&token=${token}&action=search&alternativeCaptcha=${alternativeCaptcha}`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  const results = await response.json();

  return results;
}

export async function getManualSearchResults(
  search: {
    q: string;
    page?: number;
    pageSize?: number;
    slug: any;
  },
  locale?: string
) {
  const productSearchedCopyRaw: {
    q?: string;
    page?: number;
    pageSize?: number;
    slug: any;
    search: string;
  } = { ...search, search: search.q };
  delete productSearchedCopyRaw?.slug;
  delete productSearchedCopyRaw?.q;

  const productSearchedCopy =
    productSearchedCopyRaw &&
    Object.keys(productSearchedCopyRaw)
      .map((key: string) =>
        (productSearchedCopyRaw as any)?.[key]
          ? `${key}=${(productSearchedCopyRaw as any)?.[key]}`
          : ''
      )
      .join('&');

  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const response = await fetch(
    `${process.env.NEXT_SERVER_SIDE_URL}/api/products/get-server?${productSearchedCopy}`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  const results = await response.json();
  return results;
}

export async function getSector({
  category,
  locale,
}: {
  category: string;
  locale: string | undefined;
}) {
  const sector = await fetchStrapiAPI(
    '/sectors',
    {
      filters: {
        category: {
          $eq: category,
        },
      },
      populate: [
        'banner',
        'banner.image',
        'banner.mobileImage',
        'metadata',
        'metadata.shareImage',
        'localizations',
        'categoryTexts',
        'subcategorySeo',
        'subcategorySeo.categoryTexts',
      ],
      locale,
      publicationState: 'live',
    },
    {
      encodeValuesOnly: true,
    }
  );

  if (sector.data == null || sector.data.length === 0) {
    return null;
  }

  // Return the first item since there should only be one result per slug
  return sector?.data.length === 1 ? sector?.data[0] : sector?.data;
}

export async function getSectorUI({
  idDwh,
  locale,
}: {
  idDwh: number;
  locale: string | undefined;
}) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const controller = new AbortController();
  const signal: any = controller.signal;
  const opts = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      idDwh,
      locale,
    }),
    params: JSON.stringify({
      idDwh,
      locale,
    }),
    signal,
  };

  const sectorsGetFromCms = await fetch('/api/sectors-cms', opts);
  setTimeout(() => controller.abort(), 5000);

  const sectors = await sectorsGetFromCms.json();
  return sectors;
}
