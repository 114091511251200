import React, { useEffect, useRef } from 'react';
import AliceCarousel, { Responsive } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export interface BaseCarouselProps {
  items: any[];
  mouseTracking?: boolean;
  disableDotsControls?: boolean;
  disableButtonsControls?: boolean;
  activeIndex?: number;
  paddingRight?: number;
  paddingLeft?: number;
  onSlideChanged?: ({ item }: any) => void;
  responsive?: Responsive;
  dotsAlignment?: 'left' | 'center' | 'right';
  autoPlay?: boolean;
  autoPlayControls?: boolean;
  autoPlayStrategy?: 'default' | 'all' | 'action' | 'none';
  autoPlayInterval?: number;
  animationDuration?: number;
  animationType?: 'fadeout' | 'slide';
  infinite?: boolean;
  refFromParentCb?: any;
  onSlideChange?: (e: any) => void;
}

const BaseCarousel: React.FC<BaseCarouselProps> = ({
  items,
  responsive,
  mouseTracking,
  disableDotsControls,
  disableButtonsControls,
  activeIndex,
  paddingRight,
  paddingLeft,
  onSlideChanged,
  autoPlay = false,
  autoPlayControls = false,
  autoPlayStrategy = 'none',
  autoPlayInterval = 5000,
  animationDuration = 2500,
  animationType = 'slide',
  infinite = true,
  refFromParentCb,
  onSlideChange,
}: BaseCarouselProps) => {
  const ref = useRef<AliceCarousel>();

  useEffect(() => {
    ref && refFromParentCb && refFromParentCb(ref);
  }, [ref, refFromParentCb]);

  return (
    <AliceCarousel
      responsive={responsive}
      controlsStrategy={'responsive'}
      items={items}
      autoPlay={autoPlay}
      autoPlayControls={autoPlayControls}
      autoPlayInterval={autoPlayInterval}
      autoPlayStrategy={autoPlayStrategy}
      animationType={animationType}
      animationDuration={animationDuration}
      infinite={infinite}
      mouseTracking={mouseTracking}
      disableDotsControls={disableDotsControls}
      disableButtonsControls={disableButtonsControls}
      activeIndex={activeIndex}
      onSlideChanged={onSlideChanged}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      onSlideChange={onSlideChange}
      ref={(el: AliceCarousel) => {
        ref.current = el;
      }}
    />
  );
};

export default BaseCarousel;
