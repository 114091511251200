import { styled } from '@mui/material';
import {
  ArrowBackIosOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from '../../assets';

export const Pagination = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const Page = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  margin: 0,
}));

export const StyledArrowLeftIcon = styled((props: any) => (
  <ArrowBackIosOutlinedIcon {...props} />
))(({ theme, disabled }) => ({
  width: theme.spacings.unit(7.5),
  height: theme.spacings.unit(7.5),
  cursor: disabled ? '' : 'pointer',
  color: disabled
    ? theme.colors.disabledNextPrevButton
    : theme.colors.primaryText,
  '&:hover': {
    color: disabled ? '' : theme.colors.primary,
  },
  float: 'left',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
}));

export const StyledArrowRightIcon = styled(ArrowForwardIosOutlinedIcon)(
  ({ theme, disabled }) => ({
    width: theme.spacings.unit(7.5),
    height: theme.spacings.unit(7.5),
    cursor: disabled ? '' : 'pointer',
    color: disabled
      ? theme.colors.disabledNextPrevButton
      : theme.colors.primaryText,
    '&:hover': {
      color: disabled ? '' : theme.colors.primary,
    },
    float: 'right',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  })
);
