import React from 'react';
import {
  Table as TableMui,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { TableContainerStyled } from './Table.style';

export const Table = ({ children }: { children: any }) => {
  return (
    <TableContainer component={TableContainerStyled}>
      <TableMui>{children}</TableMui>
    </TableContainer>
  );
};

export { TableBody, TableCell, TableHead, TableRow };
