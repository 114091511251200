import { styled } from '@mui/material';

export const ImageContainerStyled = styled('span', {
  shouldForwardProp: (prop) => !['isGrid', 'height'].includes(prop as string),
})(({ isGrid, height }) => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'block',
  ...(isGrid && {
    height,
    maxHeight: '255px',
    maxWidth: '33%',
    flex: '1 1 30%',
  }),
}));

export const ImageViewerWrapper = styled('div')(({ theme }) => ({
  '#ReactSimpleImageViewer': {
    backgroundColor: theme.colors.halfOpacityDark,
    zIndex: 2000,
  },

  '.react-simple-image-viewer__close, .react-simple-image-viewer__previous, .react-simple-image-viewer__next':
    {
      opacity: 0.5,
    },
}));
