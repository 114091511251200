import React from 'react';
import {
  Pagination,
  StyledArrowLeftIcon,
  StyledArrowRightIcon,
} from './PaginationArrowsMobile.style';
import { PropsWithChildren } from 'react';

interface IProps {
  index: number;
  totalItems: number;
  position?: string;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}

const PaginationArrowsMobile: React.FunctionComponent<IProps> = (
  props: PropsWithChildren<IProps>
) => {
  const { children } = props;

  const handleLeftClick = () => {
    props.onLeftArrowClick();
  };

  const handleRightClick = () => {
    props.onRightArrowClick();
  };

  return (
    <Pagination>
      <StyledArrowLeftIcon
        disabled={props.index === 0}
        onClick={props.index === 0 ? () => {} : handleLeftClick}
      />
      <StyledArrowRightIcon
        disabled={props.index === props.totalItems - 1}
        onClick={
          props.index === props.totalItems - 1 ? () => {} : handleRightClick
        }
      />
      {children}
    </Pagination>
  );
};

export default PaginationArrowsMobile;
