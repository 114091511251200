import { OutlinedTextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  InputStyled,
  InputSectionStyled,
  InputLabelStyled,
  InputContainer,
  EndIcon,
} from './Input.style';
import get from 'lodash/get';

export interface FormInputProps extends FieldProps, OutlinedTextFieldProps {
  onChangeValidate?(
    name: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  onBlurValidate?(
    name: string,
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  values: any;
}

export enum InputStyleType {
  'primary',
  'secondary',
  'disabled',
  'contactFormModal',
  'contactFormPage',
  'workshop',
}

export interface Props extends FormInputProps {
  semicontrolled?: boolean;
  showErrorOnlyIfTouched?: boolean;
  styleType?: InputStyleType;
  inline?: boolean;
  withIcon?: React.ReactElement;
  section: string;
  onChangeValue?(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  onBlurValue?(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
}

const Input: React.FC<Props> = ({
  form,
  field,
  children,
  semicontrolled,
  paddingZeros,
  onChangeValue,
  onBlurValue,
  styleType = InputStyleType.primary,
  inline = false,
  showErrorOnlyIfTouched = false,
  withIcon,
  section,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState('');
  const id = `${section}_${field.name}`;

  const { touched, errors } = form || {};

  useEffect(() => {
    setInternalValue('');
  }, [field.name]);

  const fieldError = get(errors, field?.name);
  const fieldTouched = !!get(touched, field?.name);
  const showError = showErrorOnlyIfTouched
    ? !!fieldError && fieldTouched
    : !!fieldError;

  return (
    <InputSectionStyled inline={inline}>
      {props.label && (
        <InputLabelStyled styleType={styleType} htmlFor={id}>
          {props.label}
        </InputLabelStyled>
      )}
      <InputContainer>
        <InputStyled
          {...props}
          withIcon={withIcon}
          id={id}
          name={field.name}
          disabled={styleType === InputStyleType.disabled}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ): void => {
            semicontrolled && setInternalValue(e.target.value);
            field.onChange(e);
            onChangeValue && onChangeValue(e);
          }}
          onClick={(): void => setInternalValue('')}
          onBlur={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ): void => {
            field.onBlur(e);
            onBlurValue && onBlurValue(e);
          }}
          styleType={styleType}
          value={
            semicontrolled || paddingZeros
              ? internalValue || field.value
              : field.value
          }
          error={showError.toString()}
        >
          {children}
        </InputStyled>
        {withIcon ? <EndIcon>{withIcon}</EndIcon> : null}
      </InputContainer>
    </InputSectionStyled>
  );
};

export default Input;
