import { useContext } from 'react';
import { Action, SnackbarState } from '../models/interface';
import { snackbarContext } from '../store';

export const useSnackbar = (): {
  state: SnackbarState;
  dispatch: (action: Action) => void;
} => {
  const context = useContext(snackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
