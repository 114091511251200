import React, { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { hexToRgb, rgbDataURL } from 'corporate-utils';

import { getStrapiMedia } from '../../../utils/media';

import { ImageContainerStyled, ImageViewerWrapper } from './Img.style';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

export const Img = ({
  src,
  ...props
}: {
  children: any;
  src: string;
  isPrerenderRequest: boolean;
}) => {
  const [paddingTop, setPaddingTop] = useState('0');
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);

  const openImageViewer = (): void => {
    setIsViewerOpen(!isViewerOpen);
  };

  return (
    <ImageContainerStyled style={{ paddingTop }}>
      <ImageWithFallback
        layout="fill"
        objectFit="contain"
        placeholder="blur"
        blurDataURL={rgbDataURL(
          hexToRgb('ffffff')?.[0],
          hexToRgb('ffffff')?.[1],
          hexToRgb('ffffff')?.[2]
        )}
        onLoad={({ target }: { target: HTMLImageElement }) => {
          const { naturalWidth, naturalHeight } = target as HTMLImageElement;
          setPaddingTop(`calc(100% / (${naturalWidth} / ${naturalHeight})`);
        }}
        onClick={openImageViewer}
        fallbackSrc={DEFAULT_IMAGE}
        src={getStrapiMedia(src || '')}
        {...props}
      />
      {isViewerOpen && (
        <ImageViewerWrapper>
          <ImageViewer
            src={[getStrapiMedia(src)]}
            onClose={openImageViewer}
            disableScroll={false}
            closeOnClickOutside={true}
          />
        </ImageViewerWrapper>
      )}
    </ImageContainerStyled>
  );
};
