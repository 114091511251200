import {
  capitalizeFirstLetter,
  checkExchangeableLocalization,
  internationalizationToLanguages,
} from 'corporate-utils';
import {
  defaultInternationalization,
  defaultLanguage,
  Errors,
  Languages,
  priorityInternationalization,
} from 'corporate-types';
import { Category } from '../models/domain/interfaces';
import { Logger } from 'corporate-utils';

const PRINT_FROM = false;

export const getAvailableStringFromCategoryByLocale = (
  category: Category,
  locale = defaultInternationalization,
  from?: string
): string => {
  if (from && PRINT_FROM) {
    Logger.info(from);
  }

  if (!category) {
    // throw new Error(Errors.NO_CATEGORY);
    Logger.error(Errors.NO_CATEGORY);
  }

  const convertedLocale: Languages =
    (Object.values(Languages) as string[]).indexOf(locale) !== -1
      ? (locale as Languages)
      : internationalizationToLanguages(
        locale || defaultInternationalization,
        from
      ) || defaultLanguage;

  if (!convertedLocale) {
    // throw new Error(Errors.NO_CONVERTED_LOCALE);
    Logger.error(Errors.NO_CONVERTED_LOCALE);
  }

  const languagesToCheck = [
    ...checkExchangeableLocalization(convertedLocale).map((locale) => {
      return `name${capitalizeFirstLetter(locale)}`;
    }),
    ...priorityInternationalization.map((language: string): string => {
      return `name${capitalizeFirstLetter(
        internationalizationToLanguages(
          language || defaultInternationalization,
          from
        ) || defaultLanguage
      )}`;
    }),
  ];

  let name = '';

  languagesToCheck?.reverse?.()?.forEach?.((language: string): void => {
    name = category?.[language] || name;
  });

  return name || '';
};
