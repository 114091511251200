import React, { Children, useEffect, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { hexToRgb, rgbDataURL } from 'corporate-utils';
import { useMediaQuery, useTheme } from '@mui/material';

import { getStrapiMedia } from '../../../utils/media';

import CarouselNewsImages from '../../carousel/carousel-news-images/CarouselNewsImages';

import {
  CarouselWrapper,
  GridImagesWrapper,
  ImageContainerStyled,
  ImageViewerWrapper,
} from './GridImages.style';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

export const GridImages = ({ children, ...props }: { children: any }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('md'));
  const isTablet =
    useMediaQuery(theme.breakpointsMediaQuery.up('sm')) && !isDesktop;
  const isMobile = useMediaQuery(theme.breakpointsMediaQuery.down('sm'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const [viewerCurrentIndex, setViewerCurrentIndex] = useState<number>(0);
  const [width, setWidth] = useState<number>(Number.MAX_VALUE);
  const [height, setHeight] = useState<number>(Number.MAX_VALUE);
  const images = children
    .filter((child: any) => child !== '\n')
    .map((child: any) => ({ ...child.props }));

  const openImageViewer = (index = 0): void => {
    setViewerCurrentIndex(index);
    setIsViewerOpen(!isViewerOpen);
  };

  useEffect(() => {
    if (isTablet) {
      setHeight(theme?.state?.theme?.imageHeights?.tablet);
      setWidth(theme?.state?.theme?.imageWidths?.tablet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet]);

  useEffect(() => {
    if (isMobile) {
      setHeight(theme?.state?.theme?.imageHeights?.mobile);
      setWidth(theme?.state?.theme?.imageWidths?.mobile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      {(typeof window === 'undefined' || isDesktop) && (
        <GridImagesWrapper>
          {Children.toArray(
            images.map((image: any, index: number) => (
              <ImageContainerStyled isGrid height={height}>
                <ImageWithFallback
                  layout="fill"
                  objectFit="cover"
                  placeholder="blur"
                  alt={image?.alt}
                  blurDataURL={rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )}
                  onLoad={({ target }: { target: HTMLImageElement }) => {
                    const { naturalHeight, naturalWidth } =
                      target as HTMLImageElement;
                    setWidth(naturalWidth);
                    setHeight(naturalHeight);
                  }}
                  onClick={() => openImageViewer(index)}
                  fallbackSrc={DEFAULT_IMAGE}
                  src={getStrapiMedia(image?.src || '')}
                  isPrerenderRequest={image.isPrerenderRequest}
                  {...props}
                />
              </ImageContainerStyled>
            ))
          )}
        </GridImagesWrapper>
      )}
      {typeof window !== 'undefined' && !isDesktop && (
        <CarouselWrapper>
          <CarouselNewsImages
            useImageWrapper
            onImageClick={openImageViewer}
            images={images.map((image: any, index: number) => ({
              id: index,
              attributes: {
                url: image?.src,
                alternativeText: image?.alt,
                width,
                height,
              },
            }))}
            isPrerenderRequest={images[0]?.isPrerenderRequest}
          />
        </CarouselWrapper>
      )}
      {isViewerOpen && (
        <ImageViewerWrapper>
          <ImageViewer
            src={images.map((image: any) => getStrapiMedia(image?.src))}
            currentIndex={viewerCurrentIndex}
            onClose={openImageViewer}
            disableScroll={false}
            closeOnClickOutside={true}
          />
        </ImageViewerWrapper>
      )}
    </>
  );
};
