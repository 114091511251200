import React from 'react';

import {
  BlockquoteImgStyled,
  BlockquoteImgWrapperStyled,
  BlockquoteStyled,
  BlockquoteUserNameStyled,
  BlockquoteUserRoleStyled,
  BlockquoteUserStyled,
  BlockquoteUserWrapperStyled,
  QuotationMarkStyled,
} from './Blockquote.style';

export const Blockquote = ({ children, ...props }: { children: any }) => {
  const { user, role, src } = props as any;

  const srcFromParams = (props as any)?.['data-src'] || src;
  let link;
  if (srcFromParams) {
    const linkRaw = srcFromParams.split('](');
    if (linkRaw.length === 2) {
      link = linkRaw[1].slice(0, linkRaw[1].length - 1);
    } else {
      link = srcFromParams;
    }
  }

  return (
    <BlockquoteStyled {...props}>
      <QuotationMarkStyled>
        <span>
          <i>“</i>
        </span>
      </QuotationMarkStyled>
      <i>{children}”</i>
      {((props as any)?.['data-user'] ||
        user ||
        (props as any)?.['data-role'] ||
        role ||
        link) && (
        <BlockquoteUserStyled>
          {link && (
            <BlockquoteImgWrapperStyled>
              <BlockquoteImgStyled
                src={link}
                alt={
                  ((props as any)?.['data-user'] || user || '') +
                  ((props as any)?.['data-role'] || role ? ' - ' : '') +
                  ((props as any)?.['data-role'] || role || '') +
                  ''
                }
              ></BlockquoteImgStyled>
            </BlockquoteImgWrapperStyled>
          )}
          {((props as any)?.['data-user'] ||
            user ||
            role ||
            (props as any)?.['data-role']) && (
            <BlockquoteUserWrapperStyled>
              {((props as any)?.['data-user'] || user) && (
                <BlockquoteUserNameStyled>
                  {(props as any)?.['data-user'] || user}
                </BlockquoteUserNameStyled>
              )}
              {((props as any)?.['data-role'] || role) && (
                <BlockquoteUserRoleStyled>
                  {(props as any)?.['data-role'] || role}
                </BlockquoteUserRoleStyled>
              )}
            </BlockquoteUserWrapperStyled>
          )}
        </BlockquoteUserStyled>
      )}
    </BlockquoteStyled>
  );
};
