import React from 'react';
import { ArrowDown } from '../../assets';
import { SelectType, TextSize } from '../../models/enum';
import { StyledSelectInput } from './Select.style';
import { Option } from '../../models/interface';

export interface SimpleSelectProps {
  values?: string[] | number[];
  currentValue: string | number;
  type?: SelectType;
  disabled?: boolean;
  id?: string;
  onChange: (value: string | number) => void;
  error?: boolean;
  className?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  options?: Option[];
  textSize?: TextSize;
  icon?: React.ReactElement;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  values,
  currentValue,
  id,
  onChange,
  disabled = false,
  error = false,
  className,
  variant = 'outlined',
  options,
  type,
  textSize,
  icon,
}: SimpleSelectProps) => {
  return (
    <StyledSelectInput
      onChange={(e: any) => {
        onChange(e.currentTarget.value);
      }}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      value={currentValue}
      id={id}
      disabled={disabled}
      selectType={type}
      variant={variant}
      error={error}
      IconComponent={icon || ArrowDown}
      className={className}
      disableUnderline={true}
      textSize={textSize}
    >
      {values &&
        values?.map((value, i): any => {
          return (
            <option key={i} value={value}>
              {value}
            </option>
          );
        })}
      {options &&
        options?.map((opt, index): any => {
          return (
            <option
              key={`${opt.value}_${Date.now() + index}_${opt.value}`}
              value={opt.value}
            >
              {opt.label}
            </option>
          );
        })}
    </StyledSelectInput>
  );
};

export default SimpleSelect;
