import { styled } from '@mui/material';
import { InputStyleType } from './Input';

export const InputSectionStyled = styled('section', {
  shouldForwardProp: (prop) => !['inline'].includes(prop as string),
})(({ theme, inline }) => ({
  width: '100%',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingRight: theme.fonts.utility.size(2),
  },
  [theme.breakpointsMediaQuery.down('md')]: {
    paddingRight: inline ? theme.fonts.utility.size(2) : '',
  },
}));

export const InputContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
}));

export const InputStyled = styled('input', {
  shouldForwardProp: (prop) =>
    !['styleType', 'textSize', 'withIcon'].includes(prop as string),
})(({ theme, styleType }) => {
  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    backgroundColor: theme.colors.disabled,
    borderTop: '1px solid ' + theme.colors.disabled,
    borderBottom: '1px solid ' + theme.colors.disabled,
    borderLeft: '1px solid ' + theme.colors.disabled,
    borderRight: '1px solid ' + theme.colors.disabled,
  };

  const primaryStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    backgroundColor: theme.colors.background,
    borderTop: '1px solid ' + theme.colors.primary,
    borderBottom: '1px solid ' + theme.colors.primary,
    borderLeft: '1px solid ' + theme.colors.primary,
    borderRight: '1px solid ' + theme.colors.primary,
  };

  const secondaryStyle = {
    color: theme.colors.primaryText,
    fill: theme.colors.primaryText,
    backgroundColor: theme.colors.background,
    borderTop: '0px solid ' + theme.colors.primary,
    borderBottom: '1px solid ' + theme.colors.primary,
    borderLeft: '0px solid ' + theme.colors.primary,
    borderRight: '1px solid ' + theme.colors.primary,
  };

  const workshopStyle = {
    color: theme.colors.primaryText,
    fill: theme.colors.primaryText,
    backgroundColor: theme.colors.background,
    borderTop: '0px solid ' + theme.colors.black,
    borderBottom: '1px solid ' + theme.colors.black,
    borderLeft: '0px solid ' + theme.colors.black,
    borderRight: '0px solid ' + theme.colors.black,
  };

  const contactFormStyle = {
    color: theme.colors.primaryText,
    fill: theme.colors.primaryText,
    backgroundColor: theme.colors.white,
    borderTop: '0px solid ' + theme.colors.white,
    borderBottom: '1px solid ' + theme.colors.black,
    borderLeft: '0px solid ' + theme.colors.white,
    borderRight: '0px solid ' + theme.colors.white,
  };

  const contactFormPageStyle = {
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    cursor: 'initial',
    borderTop: '0px solid ' + theme.colors.white,
    borderBottom: '1px solid ' + theme.colors.black,
    borderLeft: '0px solid ' + theme.colors.white,
    borderRight: '0px solid ' + theme.colors.white,
  };

  const getStyle = (): any => {
    switch (styleType) {
      case InputStyleType.primary:
      default:
        return primaryStyle;
      case InputStyleType.secondary:
        return secondaryStyle;
      case InputStyleType.disabled:
        return disabledStyle;
      case InputStyleType.contactFormModal:
        return contactFormStyle;
      case InputStyleType.contactFormPage:
        return contactFormPageStyle;
      case InputStyleType.workshop:
        return workshopStyle;
    }
  };

  return {
    '&:focus': {
      outline: 'none',
    },
    '::placeholder': {
      color: theme.colors.inputPlaceholder,
      opacity: 1,
      fontSize: '0.9em',
    },
    ':-ms-input-placeholder': {
      color: theme.colors.inputPlaceholder,
      fontSize: '0.9em',
    },
    '::-ms-input-placeholder': {
      color: theme.colors.inputPlaceholder,
      fontSize: '0.9em',
    },
    color: theme.colors.white,
    fontSize: '0.9em',
    transition: '0.3s',
    flex: '1',
    borderRadius: 0,
    padding: `${theme.spacings.unit(1.25)} ${theme.spacings.unit(2)}`,
    ...getStyle(),
    cursor: ((): string => {
      let result = 'text';
      result = styleType === 'readOnly' ? 'initial' : result;
      result = styleType === 'disabled' ? 'not-allowed' : result;
      return result;
    })(),
  };
});

export const EndIcon = styled('span', {
  shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
})(({ theme, styleType }) => {
  const disabledStyle = {
    fill: theme.colors.disabled,
  };

  const primaryStyle = {
    fill: theme.colors.primary,
  };

  const secondaryStyle = {
    fill: theme.colors.primaryText,
  };

  const getStyle = (): any => {
    switch (styleType) {
      case InputStyleType.primary:
      default:
        return primaryStyle;
      case InputStyleType.secondary:
        return secondaryStyle;
      case InputStyleType.disabled:
        return disabledStyle;
    }
  };

  return {
    position: 'absolute',
    top: theme.spacings.unit(0.5),
    right: theme.spacings.unit(0),
    backgroundColor: theme.colors.inputIconBackground,
    '& svg': {
      fill: getStyle().fill,
      width: '.9em',
    },
    cursor: 'pointer',
  };
});

export const InputLabelStyled = styled('label', {
  shouldForwardProp: (prop) => !['styleType'].includes(prop as string),
})(({ theme, styleType }) => {
  const defaultStyle = {
    color: theme.colors.primaryText,
  };

  const contactFormModalStyle = {
    color: theme.colors.primaryText,
  };

  const getStyle = (): any => {
    switch (styleType) {
      default:
        return defaultStyle;
      case InputStyleType.contactFormModal:
        return contactFormModalStyle;
    }
  };

  return {
    minHeight: theme.spacings.unit(1.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(1)} 0`,
    fontWeight: 600,
    ...getStyle(),
    '& svg': {
      path: {
        fill: theme.colors.primaryText,
      },
      marginRight: '8px',
    },
  };
});

export const ErrorStyled = styled('label')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(5),
  margin: `${theme.spacings.unit(0.25)} 0`,
  '& > *': {
    color: theme.colors.primaryError,
  },
  minHeight: theme.spacings.unit(1),
}));
