import React from 'react';

export const ColoredText = ({
  children,
  color,
  bold,
  fontSize,
}: {
  children: any;
  color: string;
  bold: string;
  fontSize: string;
}) => {
  return (
    <span
      style={{
        color: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)
          ? color
          : '#000000',
        fontWeight: bold ? 700 : 400,
        fontSize: fontSize ? fontSize : 'normal',
      }}
    >
      {children}
    </span>
  );
};
