import { styled } from '@mui/material';

export const ImageContainerStyled = styled('span', {
  shouldForwardProp: (prop) => !['isGrid', 'height'].includes(prop as string),
})(({ isGrid, height }) => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'block',
  ...(isGrid && {
    height,
    maxHeight: '255px',
    maxWidth: '33%',
    flex: '1 1 30%',
  }),
}));

export const ImageViewerWrapper = styled('div')(({ theme }) => ({
  '#ReactSimpleImageViewer': {
    backgroundColor: theme.colors.halfOpacityDark,
    zIndex: 2000,
  },

  '.react-simple-image-viewer__close, .react-simple-image-viewer__previous, .react-simple-image-viewer__next':
    {
      opacity: 0.5,
    },
}));

export const GridImagesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto 2rem auto',
  gap: '1rem',
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
}));

export const CarouselWrapper = styled('div')(({ theme }) => ({
  // marginLeft: `${theme.spacings.unit(36 / 4)}`,
  // marginRight: `${theme.spacings.unit(36 / 4)}`,

  li: {
    cursor: 'pointer',
  },
}));
